.breadcrumbWrapper {
  display: flex;
  width:100%;
  margin: 0;
  padding-bottom: 5px;
  flex-direction: column;
}




.paperRounded6 {
  border-radius: 6px;
  padding:8px;
}

.breadcrumbtrail {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.breadcrumbtrail li {
  padding-right: 5px;
  font-size:12px;
  
}
.breadcrumbtrail li a,link {
  text-decoration: none;
  color: rgba(0,0,0,.45);
}
.breadcrumbtrail li:last-child a,link {
  text-decoration: none;
  font-weight:bold;
  font-weight:rgba(0,0,0,.65);
}


.breadcrumbtrail li:after {
  content: '/';
  color: rgba(0,0,0,.45);
  padding-left:10px;
  padding-right:10px;
}
.breadcrumbtrail li:last-child:after {
  content: ''
}





.list_horizontal {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.list_horizontal li {
  padding-right: 5px;
  font-size:14px;
}

.article_heading {
  margin-top: 4px;
}

.flex_container {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: stretch;
}
.flex_item {
  flex-grow:1;
}