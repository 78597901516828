.PreferenceUserControls_title {
  font-size:20px;
}
.PreferenceUserControls_title_large {
  font-size:20px;
}
.PreferenceUserControls_title_medium {
  font-size:16px;
}
.PreferenceUserControls_title_small {
  font-size:14px;
}




.PreferenceUserControls_change {
  font-size:14px;
  display: flex;
  align-items: flex-end;

}
.PreferenceUserControls_desc {
  font-size:14px;
}
.PreferenceUserControls_info {
  font-size:12px;
  display: flex;
  align-items: flex-end;
}
.PreferenceUserControls_hide {
  display:none;
  opacity:0;
}
.PreferenceUserControls_contentRow {
  display:flex;
  justify-content: space-between;
  cursor:pointer;
}
.PreferenceUserControls_contentRowBottom {
  padding-bottom:8px;
}
.PreferenceUserControls_content {
  /*border-top: 1px solid #eee;*/
  padding-top: 8px;
  padding-bottom: 15px;
  transition: all 2s linear;
  margin-left:25px;
}
.PreferenceUserControls_container {
  border-top: 1px solid #ddd;
  margin-top:10px;
  /*margin-bottom:20px;*/
  position:relative;
}

/****** USER SELECTION DROPDOWN *****/
.UserSelectionDropdown_title {
  font-size:20px;
}
.UserSelectionDropdown_change {
  font-size:14px;
  display: flex;
  align-items: flex-end;

}
.UserSelectionDropdown_desc {
  font-size:14px;
}
.UserSelectionDropdown_info {
  font-size:12px;
  display: flex;
  align-items: flex-end;
}
.UserSelectionDropdown_hide {
  display:none;
  opacity:0;
}
.UserSelectionDropdown_contentRow {
  display:flex;
  justify-content: space-between;
  cursor:pointer;
}
.UserSelectionDropdown_contentRowBottom {
  padding-bottom:8px;
}
.UserSelectionDropdown_content {
  padding-top: 8px;
  padding-bottom: 15px;
  transition: all 2s linear;
  /*margin-left:25px;*/
}
.UserSelectionDropdown_container {
  border-top: 1px solid #ddd;
  margin-top:10px;
  margin-bottom:20px;
  position:relative;
}

/* COMMON */

.expander.expanded:before {
  width: 100%;
  transition: width 0.5s;
}
.expander:before {
  position: absolute;
  top: -2px;
  left: 0;
  height: 2px;
  width: 0;
  content: ' ';
  background-color: #0084bf;
}