.PageFold_panel {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
}
.PageFold_navigation {
  align-items: stretch;
  background: #F4F5F5;
}
.PageFold_navigation_narrow {
  width:48px;
}
.PageFold_navigation_expanded {
  width:250px;
}
.hide {
  display:none;
}

.foldContent {
  align-items: stretch;
  flex-grow:1;
  background-color: inherit;
  border: 2px solid rgb(241, 243, 246);
  border-left:0;
  padding: 10px;
  padding-top:20px;
}
